require('../bootstrap');

require('@fortawesome/fontawesome-free/js/all');

import { Swiper } from 'swiper';
window.Swiper = require('swiper/bundle');

var homeSwiper = new Swiper(".homeSwiper", {
    loop: true,
    autoplay: {
        delay: 5 * 1000, // 5s
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

var specialtiesSwiper = new Swiper(".swiper.specialties", {
    centeredSlides: true,
    spaceBetween: 30,
    effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 15,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
    autoplay: {
        delay: 5 * 1000, // 5s
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

var opinionsSwiper = new Swiper(".swiper.opinions", {
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoplay: {
        delay: 5 * 1000, // 5s
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

var specialtyModal = document.getElementById('specialtyModal')
specialtyModal.addEventListener('show.bs.modal', function (event) {
    let button = event.relatedTarget;
    let title = button.getAttribute('data-bs-title')
    let image = button.getAttribute('data-bs-image')
    let description = button.getAttribute('data-bs-description')
    let modalTitle = specialtyModal.querySelector('.modal-body .title')
    let modalImage = specialtyModal.querySelector('.modal-body .image')
    let modalDescription = specialtyModal.querySelector('.modal-body .description')
    modalTitle.textContent = title
    modalImage.style.backgroundImage = 'url(' + image + ')'
    modalDescription.textContent = description
});

const contactForm = document.getElementById('contactForm');
contactForm.addEventListener('submit', submitContactForm);

function submitContactForm(event) {
    event.preventDefault();
    console.log(event);
    let result = document.getElementById('contactFormResult');
    let name = document.getElementById('contactName');
    let surname = document.getElementById('contactSurname');
    let email = document.getElementById('contactEmail');
    let phone = document.getElementById('contactPhone');
    let message = document.getElementById('contactMessage');
    result.classList.add('d-none');
    result.innerHTML = null;
    axios.post(contactForm.getAttribute('action'), {
        name: name.value,
        surname: surname.value,
        email: email.value,
        phone: phone.value,
        message: message.value
    })
    .then(function (response) {
        console.log(response);
        let alert = document.createElement('div');
        alert.classList.add('alert', 'alert-success');
        let text = document.createTextNode(response);
        alert.appendChild(text);
        result.appendChild(alert);
        name.value = "";
        surname.value = "";
        email.value = "";
        phone.value = "";
        message.value = "";
    })
    .catch(function (error) {
        console.error(error);
        let alert = document.createElement('div');
        alert.classList.add('alert', 'alert-danger');
        let text = document.createTextNode(error.message);
        alert.appendChild(text);
        result.appendChild(alert);
    })
    .finally(function () {
        result.classList.remove('d-none');
    });
}

const opinionForm = document.getElementById('opinionForm');
opinionForm.addEventListener('submit', submitOpinionForm);

function submitOpinionForm(event) {
    event.preventDefault();
    console.log(event);
    let result = document.getElementById('opinionFormResult');
    let name = document.getElementById('opinionName');
    let email = document.getElementById('opinionEmail');
    let stars = document.getElementById('opinionStars');
    let comment = document.getElementById('opinionComment');
    result.classList.add('d-none');
    result.innerHTML = null;
    axios.post(opinionForm.getAttribute('action'), {
        name: name.value,
        email: email.value,
        stars: stars.value,
        comment: comment.value
    })
    .then(function (response) {
        console.log(response);
        let alert = document.createElement('div');
        alert.classList.add('alert', 'alert-success');
        let text = document.createTextNode(response.data.message);
        alert.appendChild(text);
        result.appendChild(alert);
        name.value = "";
        email.value = "";
        comment.value = "";
    })
    .catch(function (error) {
        console.error(error);
        let alert = document.createElement('div');
        alert.classList.add('alert', 'alert-danger');
        let text = document.createTextNode(error.message);
        alert.appendChild(text);
        result.appendChild(alert);
    })
    .finally(function () {
        result.classList.remove('d-none');
    });
}
